<template>
  <div>
    <LoadingMessage v-if="isLoading"></LoadingMessage>

    <CalendarWeekDays v-if="!isLoading" class="sticky dayrow"></CalendarWeekDays>

    <div :class="{ 'is-loading': isLoading }" class="d-flex flex-wrap flex-grow-1">
      <CalendarMonthDayItem
        @findProgram="showFindProgram(day.date)"
        v-for="(day, dayIndex) in days"
        :key="`key-${dayIndex}`"
        :day="day"
        :isToday="day.date === today"
        ref="day"
        class="daycol border-start border-bottom"
      ></CalendarMonthDayItem>
    </div>

    <portal to="modal">
      <transition name="modal">
        <ActionBar
          @actionBarClearSelection="actionClearSelection"
          @actionBarUpdateSelection="actionUpdateSelection"
          @actionBarCopySelection="actionCopySelection"
          @actionBarDeleteSelection="actionDeleteSelection"
          v-if="calendarGetSelectedSessions.length"
          :items="calendarGetSelectedSessions"
          :enableStatusUpdate="true"
        ></ActionBar>
      </transition>
    </portal>

    <portal to="modal">
      <transition name="modal">
        <SessionEditorModal
          @close="stopEditingSession"
          v-if="showSessionEditor"
          v-model="showSessionEditor"
          :programId="null"
          :sessionId="calendarSessionIdInEditing"
          :athleteId="userId"
        ></SessionEditorModal>
      </transition>
    </portal>

    <portal to="modal">
      <transition name="modal">
        <ClientFindProgramModal
          @close="hideFindProgramModal"
          v-if="showFindProgramModal && findProgramDate"
          v-model="showFindProgramModal"
          :userId="userId"
          :date="findProgramDate"
        ></ClientFindProgramModal>
      </transition>
    </portal>

    <portal to="modal">
      <transition name="modal">
        <SessionStatusModal
          @confirm="updateSessionsStatuses($event)"
          @close="showChangeStatusModal = false"
          v-if="showChangeStatusModal"
          v-model="showChangeStatusModal"
          :sessions="calendarGetSelectedSessions"
        ></SessionStatusModal>
      </transition>
    </portal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(weekday);

export default {
  name: 'Calendar',
  components: {
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    CalendarWeekDays: () => import('@/components/calendar/CalendarWeekDays'),
    CalendarMonthDayItem: () => import('@/components/calendar/CalendarMonthDayItem'),
    SessionEditorModal: () => import('@/components/modal/SessionEditorModal'),
    ClientFindProgramModal: () => import('@/components/modal/ClientFindProgramModal'),
    SessionStatusModal: () => import('@/components/modal/SessionStatusModal'),
    ActionBar: () => import('@/elements/ActionBar'),
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
    selectedDate: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('calendar', ['calendarSessionIdInEditing']),
    ...mapGetters('calendar', ['calendarGetSelectedSessions']),
    showSessionEditor() {
      return this.calendarSessionIdInEditing !== null;
    },
    today() {
      return dayjs().format('YYYY-MM-DD');
    },
    month() {
      return Number(this.selectedDate.format('M'));
    },
    year() {
      return Number(this.selectedDate.format('YYYY'));
    },
    numberOfDaysInMonth() {
      return dayjs(this.selectedDate).daysInMonth();
    },
    currentMonthDays() {
      return [...Array(this.numberOfDaysInMonth)].map((_, index) => ({
        date: dayjs(`${this.year}-${this.month}-${index + 1}`).format('YYYY-MM-DD'),
        isCurrentMonth: true,
      }));
    },
    previousMonthDays() {
      const firstDayOfTheMonthWeekday = this.getWeekday(this.currentMonthDays[0].date);
      const previousMonth = dayjs(`${this.year}-${this.month}-01`).subtract(1, 'month');
      const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday
        ? firstDayOfTheMonthWeekday - 1
        : 6;
      const previousMonthLastMondayOfTheMonth = dayjs(this.currentMonthDays[0].date).subtract(visibleNumberOfDaysFromPreviousMonth, 'day').date();
      return [...Array(visibleNumberOfDaysFromPreviousMonth)].map((_, index) => ({
        date: dayjs(`${previousMonth.year()}-${previousMonth.month() + 1}-${previousMonthLastMondayOfTheMonth + index}`).format('YYYY-MM-DD'),
        isCurrentMonth: false,
      }));
    },
    nextMonthDays() {
      const lastDayOfTheMonthWeekday = this.getWeekday(`${this.year}-${this.month}-${this.currentMonthDays.length}`);
      const nextMonth = dayjs(`${this.year}-${this.month}-01`).add(1, 'month');
      const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday
        ? 7 - lastDayOfTheMonthWeekday
        : lastDayOfTheMonthWeekday;
      return [...Array(visibleNumberOfDaysFromNextMonth)].map((_, index) => ({
        date: dayjs(`${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`).format('YYYY-MM-DD'),
        isCurrentMonth: false,
      }));
    },
    days() {
      return [
        ...this.previousMonthDays,
        ...this.currentMonthDays,
        ...this.nextMonthDays,
      ];
    },
  },
  watch: {
    selectedDate() {
      this.fetchSessions();
    },
  },
  methods: {
    getWeekday(date) {
      return dayjs(date).weekday();
    },
    async fetchSessions() {
      try {
        this.isLoading = true;
        this.$store.dispatch('calendar/setBusy', true);
        await this.$store.dispatch('calendar/fetchSessions', {
          dateFrom: this.days[0].date,
          dateTo: this.days[this.days.length - 1].date,
        });
        this.scrollToCurrentWeek();
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isLoading = false;
        this.$store.dispatch('calendar/setBusy', false);
      }
    },
    async deleteSessions() {
      try {
        this.$store.dispatch('calendar/setBusy', true);
        await this.$store.dispatch('calendar/deleteSessions');
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.$store.dispatch('calendar/setBusy', false);
      }
    },
    updateSessionsStatuses(sessions) {
      sessions.forEach((session) => {
        this.$store.dispatch('calendar/updateSessionStatus', session);
      });
      this.showChangeStatusModal = false;
      this.actionClearSelection();
    },
    scrollToCurrentWeek() {
      const day = this.$refs.day.find(($cmp) => $cmp.day.date === this.today);
      if (day) {
        setTimeout(() => {
          const navHeight = 48;
          const headerHeight = 70 + 1;
          const dayRowHeight = 40 + 1;
          const t = window.pageYOffset + day.$el.getBoundingClientRect().top;
          const n = navHeight + headerHeight + dayRowHeight;
          const topOffset = t - n;
          this.setScrollPosition(topOffset);
        }, 1000);
      } else {
        this.setScrollPosition();
      }
    },
    setScrollPosition(position = 0) {
      document.documentElement.scrollTop = position;
      document.body.scrollTop = position;
    },
    stopEditingSession() {
      if (this.calendarSessionIdInEditing) {
        this.$store.dispatch('calendar/setSessionInEditing', null);
      }
    },
    showFindProgram(date) {
      this.findProgramDate = date;
      this.showFindProgramModal = true;
    },
    hideFindProgramModal() {
      this.findProgramDate = null;
      this.showFindProgramModal = false;
    },
    actionClearSelection() {
      this.$store.dispatch('calendar/clearSelection');
    },
    actionUpdateSelection() {
      this.showChangeStatusModal = true;
    },
    actionCopySelection() {
      const sessions = this.calendarGetSelectedSessions;
      this.$store.dispatch('session/addSessionsToClipboard', sessions);
      this.actionClearSelection();
    },
    actionDeleteSelection() {
      this.deleteSessions();
    },
  },
  data() {
    return {
      isLoading: false,
      showFindProgramModal: false,
      showChangeStatusModal: false,
      findProgramDate: null,
    };
  },
  mounted() {
    this.$store.dispatch('calendar/setTargetAndPermissions', {
      targetId: this.userId,
      targetType: 'pt',
    });
    this.fetchSessions();
  },
  destroyed() {
    this.$store.dispatch('calendar/resetCalendar');
  },
};
</script>

<style lang="scss" scoped>
.is-loading {
  opacity: 0;
  pointer-events: none;
}

.dayrow {
  top: calc(3rem + 70px + 1px);
}

.daycol {
  min-height: 12rem;

  &:nth-child(7n+1) {
    border-left: 0 !important;
  }

  &:nth-child(-n+7) {
    border-top: 0;
  }
}
</style>
